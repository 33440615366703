<template>
  <div>
    <div class="d-flex my-4">
      <div><router-link to="/"> <i class="el-icon-s-home"></i>跃迁出国</router-link></div>
      <div class="mx-3 color999">/</div>
      <div><router-link to="/hot"> 关于跃迁</router-link></div>
      <div class="mx-3 color999">/</div>
      <div><router-link to="/news"> 移民资讯</router-link></div>
    </div>
    <div class="title mb-4">{{c_title}}</div>
    <div>
      <span style="margin-right:20px;">{{c_date}}</span> 来源：<a href="/">跃迁出国</a>
    </div>
    <hr>
  </div>

</template>
<script>
export default {
  name: 'NewsHeader',
  props:['c_title','c_date']
}
</script>
<style scoped>
a {
  color: #428bca;
  text-decoration: none;
}

.title {
  font-size: 24px;
  text-align: center;
}
</style>