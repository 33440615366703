<template>
  <div class="py-3 d-flex justify-content-center"
       style="background-color: #f2f2f2;    border-bottom: #428bca 10px solid;">
    <div class="w1180 bg-white p-4 my-4">
      <NewsHeader :c_title="info.title" :c_date="info.date"/>

      <div class="x12 text-big">
        <p><img loading="lazy" class="aligncenter wp-image-22724" src="@/assets/news/2/1.jpg" alt="" width="758" height="505" sizes="(max-width: 758px) 100vw, 758px"></p>
        <p>在疫情期间，加拿大也没有停止接纳移民的步伐，“3年百万移民计划”也正在进行中。但由于疫情的影响和限制，加拿大去年仅接受了18.4万新移民，远未实现34.1万的目标。为了推动经济发展，完成移民任务，加拿大政府将加快接受新移民的进度，计划在今年实现40.1万新移民的目标。</p>
        <p>据CIC NEWS报道，加拿大今年1月共计接收近25000名新移民。这是自疫情爆发以来第一次新移民吸收数量恢复到疫情之前的水平。为了在今年实现移民目标，加拿大展开了一轮“大动作”。<br>
          给境内申请人开绿灯</p>
        <p>在2月13日，加拿大移民局（IRCC）上演了一场史无前例的“大赦天下”，狂送PR身份，沸腾了整个留学圈子。经验类移民特快通道（EE CEC类别）池子内的27332名境内申请人一次性全体上岸，邀请分数更是创历史新低75分。</p>
        <p>IRCC此次如此大规模的抽签行为，旨在让更多有资格的境内申请人拿到PR。在政府积极的推动下，加拿大在今年实现40.1万新移民目标的决心可见一斑。</p>
        <p>根据IRCC公布的2021年移民配额比例：<br>
          60%为经济类移民<br>
          25%为家庭团聚类移民<br>
          15%为难民类移民</p>
        <p>60%的经济类移民中，配额比例分别为：<br>
          联邦快速通道 EE 配额为10.85万个<br>
          自雇移民及SUV创业签证配额为1000个<br>
          护工、农产品、北部试点项目8500个<br>
          大西洋四省试点AIPP计划6000个<br>
          各省省提名项目8.08万个</p>
        <p>特别提醒的是，加拿大的大赦邀请并不是长期持续的，未来是否还会有类似操作，我们无法保证。移民局或将临时放宽PR申请门槛</p>
        <p>除了AIPP之外，大多数其他经济类移民的申请处理时间通常需要六个月以上，而对于PNP和魁省而言，处理时间约2年。鉴于旅行限制，IRCC若想实现今年的目标，就需要加快处理申请。</p>
        <p>值得强调的是，联邦移民部和各省今年都希望接收更多新移民，以弥补之前的不足。这是一项临时的政策调整，移民部近期已表示，期待在旅行限制措施解除之后接收更多的移民。同时，海外人士可以继续提交移民申请，那些已经获批的人士在疫情改善后，便可登陆加拿大。</p>
        <p>疫情期间，虽然不能自由通行对“百万移民计划”产生了影响，但由于加拿大移民局不断加强吸收新移民的力度，促使了政策的临时放宽，这对于一直观望加拿大的投资人来说确实是个难得的机会。</p>
        <p>所以，早作计划，打破观望，抓住移民利好机遇，你还要再等么？</p>
        <p><strong>曼省企业家移民</strong></p>
        <p>曼省企业家移民项目申请条件</p>
        <p>资产要求：家庭净资产达到50万加币以上<br>
          管理经验：近5年中有3年以上管理经验<br>
          语言要求：雅思听说读写每项分数5/5/4/5及以上<br>
          创业要求：最低投资额仅需15万加币，雇佣至少一个加拿大公民或永久居民</p>
        <p><strong>曼省企业家移民项目优势</strong></p>
        <p>门槛低——家庭净资产只需要50万加币，高管/企业主均可申请<br>
          成本低——投资额15万加币起<br>
          工签阶段——无需登陆，全程国内办理</p>
        <p><img loading="lazy" class="aligncenter wp-image-22854" src="@/assets/news/2/2.jpg" alt="" width="616" height="1193"  sizes="(max-width: 616px) 100vw, 616px"></p>
        <p><strong>安省企业家移民</strong></p>
        <p>安省企业家移民申请条件</p>
        <p><strong>商业要求：</strong>五年内有两年的企业主（持股 33.3% 以上）/ 高管经验</p>
        <p><strong>个人资产：</strong>如果拟经营公司位于</p>
        <p>a.大多伦多地区（包括多伦多市、达勒姆、哈尔顿、约克和皮尔地区），申请人必须拥有至少80万加元的净资产</p>
        <p>b.大多伦多地区以外，申请人必须拥有至少40万加元的净资产</p>
        <p>c.如果业务范围是在信息和通讯技术/ 数字通信领域，不论地区，申请人都必须拥有至少40万加元的净资产</p>
        <p><strong>投资要求：</strong>如果拟经营公司位于</p>
        <p>a.大多伦多地区，申请人必须至少投资60 万加元，并且持股三分之一以上</p>
        <p>b.大多伦多地区以外，申请人必须至少投资20 万加元，并且持股三分之一以上</p>
        <p>c.如果业务范围是在信息和通讯技术/ 数字通信领域，不论地区，申请人都必须至少投资20 万加元，并且持股三分之一以上</p>
        <p><strong>创造就业：</strong>如果拟经营公司位于</p>
        <p>a.大多伦多地区，申请人必须为加拿大公民/ 永久居民创造至少两个永久性的全职工作</p>
        <p>b.大多伦多地区以外，或者是信息和通讯技术/ 数字通信领域的企业，申请人必须为加拿大公民/ 永久居民创造至少一个永久性的全职工作</p>
        <p>c.职位必须在建立或收购企业后的前20个月内创造，职员必须连续工作至少10个月，并必须按该职位的工资中位数支付工资</p>
        <p><strong>如果计划购买现成的企业</strong></p>
        <p>a. 申请人提交EOI 之前的12 个月内需要到安省进行一次商务考察</p>
        <p>b. 被收购的企业必须由同一所有者在过去60 个月之内持续经营</p>
        <p>c. 企业的所有权必须完全转让给申请人或任何业务伙伴</p>
        <p>d. 申请人必须至少将个人投资的10% 用于改善或扩大在安省的业务</p>
        <p>e. 申请人必须保留转让前在公司工作的永久全职员工</p>
        <p><strong>工签转永居要求</strong></p>
        <p>1.语言达到CLB 4或以上（雅思听 4.5，说 4.0，读 3.5，写 4.0）</p>
        <p>2.申请人1年内有至少9个月的时间居住在安省</p>
        <p>3.申请人必须积极参与企业每日管理</p>
        <p>4.申请人和企业在登陆20个月之内必须满足安省Performance Agreement的要求，包括企业地址、投资金额、创造职位数量等。所有协议要求都是基于EOI 打分和商业计划而签订的。</p>
        <p><img loading="lazy" class="aligncenter wp-image-22662" src="@/assets/news/2/3.jpg" alt="" width="707" height="1136"  sizes="(max-width: 707px) 100vw, 707px"></p>
        <p><strong>魁省投资移民</strong></p>
        <p>目前，魁北克移民局计划于2021年4月重新开放投资移民项目，时间紧迫，建议有意向的投资人尽早准备，才能更稳妥地顺利通过预审，移早不移迟是不变的真理！</p>
        <p>魁北克省投资移民申请条件</p>
        <p>资产要求：拥有超过200万加币的净资产；<br>
          管理经验：近五年中至少拥有两年以上企业管理经验；<br>
          资产合法来源：所有申报资产的合法来源证明；<br>
          投资要求：一次性支付35万加币（无返还）。<br>
          符合体检要求、符合安全背景调查要求、无刑事犯罪记录。</p>
        <p><strong>魁北克省投资移民项目优势</strong></p>
        <p>方便快捷获身份，无转换身份的后顾之忧；<br>
          投资方式简单，一次性支付35万加币；<br>
          投资安全可靠，审核通过后才需支付投资款；<br>
          政策成熟稳定，已有30余年历史；<br>
          无语言、学历要求；<br>
          高福利的宜居国家，教育经商养老等环境优渥。</p>
        <p>&nbsp;</p>
        <p><strong>加拿大自雇移民</strong></p>
        <p>加拿大联邦自雇，帮您轻松移民加拿大，乐享更多福利，享受高端教育和完善的医疗服务。</p>
        <p>加拿大联邦自雇移民（Federal Self-employed Persons Program）属于加拿大联邦的商业移民类别。</p>
        <p>联邦自雇移民项目旨在吸引从事文化、艺术、体育事业的相关行业人才*，如作家、画家、艺术家、演员、运动员等，以自雇形式移民加拿大，在不占用加拿大就业市场工作机会的情况下，以个人的才能及专业技术为自己创造工作，并为加拿大的文化或体育行业创造重大贡献。</p>
        <p>*所从事的行业需在加拿大国家职业列表中定义的Occupations in art, culture, recreation and sport 范围内。</p>
        <p><strong>加拿大联邦自雇移民优势</strong></p>
        <p>🌟福利高<br>
          一人申请全家移民，移民后享受加拿大优渥福利、免费医疗、子女可享受加拿大高质量教育，高中及以下公立教育免费；</p>
        <p>🌟流程简<br>
          一步到位获枫叶卡，无需抽签，无需省提名；</p>
        <p>🌟门槛低<br>
          对语言、学历无硬性要求，无需管理经验，资产要求宽松，无强制投资或创业要求；</p>
        <p>🌟速度快<br>
          办理周期短至22 个月*，快速登陆加拿大；</p>
        <p>🌟自由住<br>
          没有居住省份的限制，可在加拿大境内自由定居。</p>
        <p>* 周期为当前办理周期，仅供参考，具体办理周期以个案实际发生为准</p>
        <p><strong>申 请 要 求</strong></p>
        <p>1、申请前五年有至少两年艺术、文化、体育方面的工作经验：<br>
          参加过世界级的文化或体育活动，或；<br>
          有艺术、文化、体育行业自雇经验（依靠自己在艺术、文化或体育方面的才能或作品获得主要收入）。<br>
          可以是上述两类经验各一年，或者每种经验两年</p>
        <p>2、申请者必须有意向，有能力将来去加拿大创立文体方面的事业，且至少能为申请者自己创造就业机会（自雇），且能够为加拿大的文化、体育行业创造重大贡献。</p>
        <p>3、项目评分标准满分为100，达到35分即可递交申请。</p>
        <p>4、有足够的资金可以支持自己和随行家庭人员抵加之后一年的生活开支及未来发展计划。</p>
        <p>5、全家通过体检，无犯罪记录。</p>
        <p>加拿大，不仅自然环境优美，教育体制优越，还连续多年被联合国评选为世界上适宜居住的国家。不仅完善的社会福利保障制度令很多其他国家的人们所羡慕，优越的自然环境更给人以轻松舒适的生活节奏在这里可以过上悠闲自在的生活。</p>
        <p>这样一个少有所教、老有所养、病有所医的国家实在是很难不让人心动。</p>
        <p>如果您想了解自己是否符合申请条件，可拨打免费咨询热线：13764320113&nbsp; 了解更多详细内容，我们将安排专业移民顾问为您解答。</p>
      </div>

      <NewsFooter class="mt-5" :next="next" :prev="prev"/>
    </div>
  </div>

</template>

<script>
import NewsHeader from "@/components/News/NewsHeader";
import NewsFooter from "@/components/News/NewsFooter";
import news from "@/news";

export default {
  name: "news1",
  components: {NewsHeader, NewsFooter},
  data() {
    return {
      id:2,
      info: {},
      next: null,
      prev: null
    }
  }, mounted() {
    let id = this.id
    this.info = news.find(item => item.id === id)
    this.next = news.find(item => item.id === (id + 1))
    this.prev = news.find(item => item.id === (id - 1))
  }
}
</script>

<style scoped>
.aligncenter {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

a {
  color: #428bca;
  text-decoration: none;
}
</style>