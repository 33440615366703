<template>
  <div class="text-center my-4">
    上一篇: <router-link :to="'/news/'+ prev.id" rel="prev" v-if="prev">{{ prev.title }}</router-link> <span v-else>没有了</span>
    下一篇: <router-link :to="'/news/'+ next.id" rel="next" v-if="next">{{ next.title }}</router-link> <span v-else>没有了</span>
  </div>
</template>
<script>
export default {
  name: 'NewsFooter',
  props: {
    next: {},
    prev: {}
  }
}
</script>
<style scoped>

a {
  color: #428bca;
  text-decoration: none;
}
</style>